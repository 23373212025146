import React from "react";

import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import styles from "./platform-grid.css";

interface Props {
  title: string;
  items: {
    id: string;
    link: string;
    title: string;
    description: string;
    image: IGatsbyImageData;
  }[];
}

export const PlatformGrid: React.FC<Props> = ({ title, items }) => {
  return (
    <div className={styles.platformsContainer}>
      <h3 className={styles.platformsTitle}>{title}</h3>
      <div className={styles.platformsWrap}>
        {items.map((platform: any) => (
          <div key={platform._key}>
            <GatsbyImage
              alt={platform.name}
              className="hide-in-dark-mode"
              image={platform.imageLight.asset.gatsbyImageData}
            />
            <GatsbyImage
              alt={platform.name}
              className="show-in-dark-mode"
              image={platform.imageDark.asset.gatsbyImageData}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
