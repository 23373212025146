import React from "react";

import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import styles from "./service-grid.css";

interface Props {
  items: {
    id: string;
    title: string;
    description: string;
    image: IGatsbyImageData;
  }[];
}

export const ServiceGrid: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles.servicesContainer}>
      {items.map(({ _key, title, description, image }: any) => (
        <div key={_key} className={styles.serviceWrap}>
          <div className={styles.servicesImage}>
            <GatsbyImage alt={title} image={image.asset.gatsbyImageData} />
          </div>
          <div className={styles.serviceTextWrap}>
            <h3 className={styles.serviceTitle}>{title}</h3>
            <p className={styles.serviceDescription}>{description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
