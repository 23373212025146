import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import { ButtonSpecial } from "../../ui/button-special";
import styles from "./cta.css";

interface Props {
  text?: string;
}

export const Cta: React.FC<Props> = ({ text }) => {
  const { cta } = useStaticQuery(graphql`
    query CtaPlatforms {
      cta: sanityBlockCtaPlatforms {
        title
        button
      }
    }
  `);

  return (
    <div className={styles.ctaPlatformsConstainer}>
      <p className={styles.ctaPlatformsText}>{text || cta.title}</p>
      <ButtonSpecial text={cta.button} to="/presupuesto" variant="outline" />
    </div>
  );
};
