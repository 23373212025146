import React from "react";

import { graphql, PageProps } from "gatsby";

import { Container } from "../components/container";
import { Cta } from "../components/cta";
import { LayoutPage } from "../components/layout-page";
import { PlatformGrid } from "../components/platform-grid";
import { Seo } from "../components/seo";
import { ServiceGrid } from "../components/service-grid";
import { Title } from "../components/title";
import styles from "../styles/headless.css";

export default function HeadlessPage({ data: { page } }: PageProps<any>) {
  return (
    <>
      <Seo
        description={page.seo.description}
        image={page.seo.image?.asset.gatsbyImageData.images.fallback.src}
        title={page.seo.title}
      />

      <LayoutPage
        heroButtonText={page.hero.button}
        heroDescription={page.hero.description}
        heroImage={page.hero.image.asset.gatsbyImageData}
        heroImageAlt="Headless"
        heroTitle={page.hero._rawTitle}
      >
        <Container className={styles.containerServices} variant="solid">
          <Title
            pretitle={page.sectionServicesHeader.pretitle}
            subtitle={page.sectionServicesHeader.subtitle}
            text={page.sectionServicesHeader.title}
          />

          <ServiceGrid items={page.sectionServicesItems} />

          <Cta />
        </Container>

        <Container className={styles.containerPlatfroms}>
          <Title
            pretitle={page.sectionPlatfromsHeader.pretitle}
            subtitle={page.sectionPlatfromsHeader.subtitle}
            text={page.sectionPlatfromsHeader.title}
          />

          <PlatformGrid items={page.sectionPlatfromsECommerceItems} title="Headless eCommerce" />

          <PlatformGrid items={page.sectionPlatfromsCMSItems} title="Headless CMS" />

          <PlatformGrid items={page.sectionPlatfromsSSGItems} title="Framework" />

          <PlatformGrid items={page.sectionPlatfromsDeploymentItems} title="Deployment Platform" />
        </Container>
      </LayoutPage>
    </>
  );
}

export const query = graphql`
  query {
    page: sanityPageHeadless {
      seo {
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, width: 1200, height: 630)
          }
        }
      }
      hero {
        _rawTitle
        description
        button
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 1050)
          }
        }
      }
      sectionServicesHeader {
        pretitle
        title
        subtitle
      }
      sectionServicesItems {
        _key
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 400)
          }
        }
      }
      sectionPlatfromsHeader {
        pretitle
        title
        subtitle
      }
      sectionPlatfromsECommerceItems {
        _key
        name
        imageLight {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
        imageDark {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
      }
      sectionPlatfromsCMSItems {
        _key
        name
        imageLight {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
        imageDark {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
      }
      sectionPlatfromsSSGItems {
        _key
        name
        imageLight {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
        imageDark {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
      }
      sectionPlatfromsDeploymentItems {
        _key
        name
        imageLight {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
        imageDark {
          asset {
            gatsbyImageData(fit: SCALE, placeholder: BLURRED, height: 48)
          }
        }
      }
    }
  }
`;
